<template lang="pug">
  b-card.manager-contacts-link_networks-form.card-white.card-no-gutters.shadow(no-body)
    b-card-header.bg-transparent.border-0
      h5.m-0
        | {{ $t('activerecord.models.contact/link_network.one') }}
    b-form.form-wc.form-contacts-link_network-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            b-form-group#form-link_network-name(
              :label="$t('activerecord.attributes.shared.name')"
              label-for='link_network-name'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#link_network-name.form-input-first.shadow-sm.is-required(
                name='name'
                type='text'
                autocomplete='name'
                :placeholder="$t('contact/link_network.placeholders.name')"
                :state='formValidationState($v.form.data.attributes.name)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.name.$model'
                aria-describedby='link_network-name-feedback'
                trim)
              wc-forms-if#link_network-name-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.name'
                :remote="formRemoteInvalidFeedback('name')"
                :validators='{ required: {}, maxLength: {} }')

            b-form-group#form-link_network-color(
              :label="$t('activerecord.attributes.contact/link_network.color')"
              label-for='link_network-color'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#link_network-color.form-input-middle.shadow-sm.is-required(
                name='color'
                type='text'
                autocomplete='color'
                :placeholder="$t('contact/link_network.placeholders.color')"
                :state='formValidationState($v.form.data.attributes.color)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.color.$model'
                aria-describedby='link_network-color-feedback'
                trim)
              wc-forms-if#link_network-color-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.color'
                :remote="formRemoteInvalidFeedback('color')"
                :validators='{ required: {}, regExp: {} }')

            b-form-group#form-link_network-icon(
              :label="$t('activerecord.attributes.contact/link_network.icon')"
              label-for='link_network-icon'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#link_network-icon.form-input-middle.shadow-sm.is-required(
                name='icon'
                type='text'
                autocomplete='icon'
                :placeholder="$t('contact/link_network.placeholders.icon')"
                :state='formValidationState($v.form.data.attributes.icon)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.icon.$model'
                aria-describedby='link_network-icon-feedback'
                trim)
              wc-forms-if#link_network-icon-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.icon'
                :remote="formRemoteInvalidFeedback('icon')"
                :validators='{ required: {}, maxLength: {} }')

            b-form-group#form-link_network-validation(
              :label="$t('activerecord.attributes.contact/link_network.validation')"
              label-for='link_network-validation'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#link_network-validation.form-input-middle.shadow-sm.is-required(
                name='validation'
                type='text'
                autocomplete='validation'
                :placeholder="$t('contact/link_network.placeholders.validation')"
                :state='formValidationState($v.form.data.attributes.validation)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.validation.$model'
                aria-describedby='link_network-validation-feedback'
                trim)
              wc-forms-if#link_network-validation-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.validation'
                :remote="formRemoteInvalidFeedback('validation')"
                :validators='{ required: {}, maxLength: {} }')

            b-form-group#form-link_network-validation_browser(
              :label="$t('activerecord.attributes.contact/link_network.validation_browser')"
              label-for='link_network-validation_browser'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#link_network-validation_browser.form-input-last.shadow-sm.is-required(
                name='validation_browser'
                type='text'
                autocomplete='validation_browser'
                :placeholder="$t('contact/link_network.placeholders.validation_browser')"
                :state='formValidationState($v.form.data.attributes.validation_browser)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.validation_browser.$model'
                aria-describedby='link_network-validation_browser-feedback'
                trim)
              wc-forms-if#link_network-validation_browser-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.validation_browser'
                :remote="formRemoteInvalidFeedback('validation_browser')"
                :validators='{ required: {}, maxLength: {} }')

      b-card-footer.border-0.bg-white
        wc-forms-buttons#form-link_network-buttons(:disabled='formDisable || $v.form.data.$invalid')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import WcFormsButtons from '@components/shared/forms/WcFormsButtons'
import apiLinkNetworks from '@services/api/manager/globals/contacts/link_networks'
import { Form } from '@common/form'
import { regExp } from '@common/form/validations'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'manager-contacts-link_networks-form',
  mixins: [Form],
  components: {
    WcFormsButtons,
  },
  computed: {
    apiParams() {
      return {
        get: [{ id: this.link_network_id || 'new' }, {}],
        create: [
          {},
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        update: [
          { id: this.link_network_id },
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
      }
    },
  },
  validations() {
    return {
      form: {
        data: {
          attributes: {
            name: {
              required,
              maxLength: maxLength(this.$wc.conf.limits.max_name),
              remote: () => this.formRemoteValid('name'),
            },
            color: {
              required,
              regExp: regExp(this.$wc.conf.regex.hex_color_browser),
              remote: () => this.formRemoteValid('color'),
            },
            icon: {
              required,
              maxLength: maxLength(this.$wc.conf.limits.max_icon),
              remote: () => this.formRemoteValid('icon'),
            },
            validation: {
              required,
              maxLength: maxLength(this.$wc.conf.limits.max_validation),
              remote: () => this.formRemoteValid('validation'),
            },
            validation_browser: {
              required,
              maxLength: maxLength(this.$wc.conf.limits.max_validation),
              remote: () => this.formRemoteValid('validation_browser'),
            },
          },
        },
      },
    }
  },
  data() {
    return {
      apiBase: apiLinkNetworks,
      apiModel: 'link_network',
      link_network_id: this.$route.params.link_network_id,
    }
  },
}
</script>
